/* eslint-disable */
/**
 * Trust All Scripts
 *
 * This is a dirty little script for iterating over script tags
 * of your Ghost posts and adding them to the document head.
 *
 * This works for any script that then injects content into the page
 * via ids/classnames etc.
 *
 */
var trustAllScripts = function () {
    var scriptNodes = document.querySelectorAll('.load-external-scripts script');

    for (var i = 0; i < scriptNodes.length; i += 1) {
        var node = scriptNodes[i];
        var s = document.createElement('script');
        s.type = node.type || 'text/javascript';

        if (node.attributes.src) {
            s.src = node.attributes.src.value;
        } else {
            s.innerHTML = node.innerHTML;
        }

        document.getElementsByTagName('head')[0].appendChild(s);
    }

    /* this is a typeform related script :)
     * 
     */
    const typeformScript = document.createElement('script');
    typeformScript.type = 'text/javascript';
    typeformScript.src ="//embed.typeform.com/next/embed.js";
    document.getElementsByTagName('head')[0].appendChild(typeformScript);
    
    const typeformDiv = document.createElement('div');
    typeformDiv.setAttribute("data-tf-popover","hoQnmhvX")
    typeformDiv.setAttribute("data-tf-button-color","#0445AF")
    typeformDiv.setAttribute("data-tf-chat","true")
    typeformDiv.setAttribute("data-tf-tooltip","Hey 👋&nbsp;&nbsp;Help me improve this blog :)")
    typeformDiv.style="all:unset;"
    document.getElementsByTagName('head')[0].appendChild(typeformDiv);

    
    /* this is a Adsense related script :)
     * 
     */

    const AdsScript = document.createElement('script');
    AdsScript.type = 'text/javascript';
    AdsScript.src ="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9676779836892401";
    AdsScript.setAttribute("crossorigin","anonymous");
    document.getElementsByTagName('head')[0].appendChild(AdsScript);

};

exports.onRouteUpdate = function () {
    trustAllScripts();
};
