module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"0ZOJBuZPAqhp81NKRhUGELMqmGWDN8Cj","devKey":"0ZOJBuZPAqhp81NKRhUGELMqmGWDN8Cj","trackPage":false,"trackPageDelay":50,"host":"https://brightshine.io","delayLoad":false,"delayLoadTime":1000,"manualLoad":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-YCRKD3WDMM","head":false,"pageTransitionDelay":0,"optimizeId":"GTM-5PHS8DZ","defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"brightshine.io","enableWebVitalsTracking":true,"anonymize":false,"respectDNT":false,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YCRKD3WDMM","UA-172935308-1"],"pluginConfig":{"head":true,"anonymize_ip":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
